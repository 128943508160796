import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { createTrackingEvent, afLink, isBrowser } from "lib/tracking";
import AmazonStoreIcon from "assets/icons/appstores/amazon.svg";
import GoogleStoreIcon from "assets/icons/appstores/google.svg";
import AppleStoreIcon from "assets/icons/appstores/apple.svg";
import WindowsAppStoreIcon from "assets/icons/appstores/windows.svg";
import * as AppDownloadsStyling from "./AppDownload.module.scss";
import PropTypes from "prop-types";

const AppDownloads = ({ block }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          appDownloads {
            player {
              afLink
              platform
              url
            }
            remote {
              afLink
              platform
              url
            }
          }
        }
      }
    }
  `);

  const application = block.application.toLowerCase();
  const links = data.site.siteMetadata.appDownloads[application];
  const icons = {
    apple: <AppleStoreIcon />,
    windows: <WindowsAppStoreIcon />,
    amazon: <AmazonStoreIcon />,
    google: <GoogleStoreIcon />,
  }

  return (
    <div className="row">
      <div className={`col sm-12 ${AppDownloadsStyling.appWrapper}`}>
        {links.map((app, index) => {
          return <LinkWrapper
            key={index}
            app={app}
          >
            {icons[app.platform]}
          </LinkWrapper>
        })}
      </div>
    </div>
  );
};

function LinkWrapper({ index, app, children }) {
  const [resolvedLink, setResolvedLink] = React.useState()
  React.useEffect(() => {
    if (isBrowser() && app.afLink) {
      afLink({
        params: {
          af_android_url: app.url,
          af_web_dp: app.url,
          af_ios_url: app.url,
          af_siteid: 'storefront',
        }
      })
        .then((l) => setResolvedLink(l))
    } else {
      setResolvedLink(app.url)
    }
  }, [app])

  return (
    <a
      onClick={() =>
        createTrackingEvent(
          "Storefront - App Store CTA Click",
          {
            "App Store Click Name": app.platform,
          },
          "Click",
          { "App Store Click Name": app.platform },
          app.platform
        )
      }
      key={index}
      href={resolvedLink}
      target="_blank"
      rel="noreferrer"
      className={AppDownloadsStyling.appStore}
    >
      {children}
    </a>
  )
}

export default AppDownloads;

AppDownloads.propTypes = {
  block: PropTypes.shape({
    application: PropTypes.string.isRequired,
  }).isRequired,
};
